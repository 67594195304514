const host = window && window.location && window.location.host
var hostname = window && window.location && window.location.hostname

export const CDN_URL = `https://${host}/`
export const API_ROOT = 'https://data.bookeeapp.com' // Your api url

export const IMAGE_PATH = 'assets/images/'
export const CDN_PATH = CDN_URL + IMAGE_PATH
export const SEARCH_REDIRECT = ''
export const ENVIRONMENT = 'production'
export const PROD = true
export const POSTHOG_APIKEY = 'phc_esiedmts6bY0UoBvUG9AbQqXaAmHz6bdVTJFqlCouvr'
